import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import './loading-skeleton.styles.scss';

const LoadingSkeleton = () => (

    <div className='skeleton-body' style={{ fontSize: 20, lineHeight: 2 }}>
      <SkeletonTheme color="#f4f4f4" highlightColor="#c4c4c4">
        <div className='skeleton-title'>
          {<Skeleton height={40} width={156} />}
        </div>
      </SkeletonTheme>
      <SkeletonTheme color="#f4f4f4" highlightColor="#00cded">
        <div className='skeleton-paragraph'>
          {<Skeleton height={30} width={256} count={4} />}
        </div>
      </SkeletonTheme>
    </div>

);

export default LoadingSkeleton;
