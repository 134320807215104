import React from 'react';

import Header from '../../components/header/header.component';
import TaskAvailable from '../../components/schedule-visit-task/schedule-visit-task.component';
import Footer from '../../components/footer/footer.component';

const AvailableTask = (props) => (
  <div className='parent'>
    <Header />

    <TaskAvailable {...props} />

    <Footer />
  </div>
);

export default AvailableTask;
