import React from 'react';
import Header from '../../components/header/header.component';
import Footer from '../../components/footer/footer.component';
import Tasks from '../../components/tasks/tasks.component';
import './task-list.styles.scss';


const TaskList = (props) => {
  return (
    <div className='parent'>
      <Header /> 
      <div className='task-list'>
        <div className='task-list-title'>
          <h3>Mis servicios</h3>
        </div>

        <Tasks {...props} />

      </div>

      <Footer />
    </div>
  )
};

export default TaskList;
