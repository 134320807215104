import Swal from "sweetalert2";
import './popup-message.styles.scss';

const PopupMessage = (icon, title, taskId, route) => {
  Swal.fire({
    icon: icon,
    title: title,
    allowOutsideClick: false,
    showConfirmButton: false,
    showCloseButton: true,
    customClass: 'mh-modal',
    onClose: () => {
      if(route){
        window.location = '/' + route;
      } else if (icon === 'success' && taskId !== 'none') {
        window.location = '/' + taskId;
      }
    }
  });
};

export default PopupMessage;
