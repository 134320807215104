import React from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { GoPlus } from "react-icons/go";
import { Link } from 'react-router-dom';


export default class MonthEvent extends React.Component {

    render() {
        return (
        <>{this.props.event.isNew ? (
            <div className="new-event">< GoPlus className="icono-plus" /></div>
            ) : (
                <div className="event-day-box tooltipBoundary">
                    <Popup
                    trigger={
                        <div className="event-day-box-task">
                            {this.props.title}
                        </div>
                    }
                    position={'bottom center'}
                    closeOnDocumentClick
                    keepTooltipInside=".rbc-month-view"
                    ><div className="eventooltip">
                        {this.props.event.isVisit?(
                         <p>VISITA ID <span className="span-id"><Link to={{pathname: `/${this.props.event.id}`}}>{this.props.event.id}</Link></span></p>
                      ):(
                        <p>ID <span className="span-id"><Link to={{pathname: `/${this.props.event.id}`}}>{this.props.event.id}</Link></span></p>
                      )}
                        <p>{this.props.event.popaplabeldate}</p> 
                        <p>{this.props.event.location}</p>
                    </div>
                    </Popup>
                </div>
            )}
        </>
        );
    }
}