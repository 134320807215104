import React from 'react';
import PageNotFound from '../../assets/images/map404.svg';
import Header from "../../components/header/header.component";
import Footer from "../../components/footer/footer.component";
import './not-found-page.styles.scss';


const NotFoundPage = () => (
  <div className='parent'>

    <Header/>

    <div className='profile'>
      <h3 className='title-404'>404</h3>
      <img  src={PageNotFound} alt='404' height='200' width='200' />
    </div>

    <Footer/>

  </div>
);

export default NotFoundPage;
