import React from 'react';

import Header from '../../components/header/header.component';
import VisitTaskCalendar from '../../components/accept-visit-calendar/accept-visit-calendar.component';
import Footer from '../../components/footer/footer.component';

const VisitCalendar = (props) => (
  <div className='parent'>
    <Header />

    <VisitTaskCalendar {...props} />

    <Footer />
  </div>
);

export default VisitCalendar;
