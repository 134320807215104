import React from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { Link } from 'react-router-dom';

export default class WeekEvent extends React.Component {

    render() {
        return (
        <><div 
        className="tooltipBoundary event-day-box">
                <Popup
                trigger={
                    <div className='week-event event-day-box-task'>
                        {this.props.title}
                    </div>
                }
                position={'bottom center'}
                closeOnDocumentClick
                keepTooltipInside=".rbc-time-view"
                ><div className="eventooltip">
                    {this.props.event.isVisit?(
                         <p>VISITA ID <span className="span-id"><Link to={{pathname: `/${this.props.event.id}`}}>{this.props.event.id}</Link></span></p>
                      ):(
                        <p>ID <span className="span-id"><Link to={{pathname: `/${this.props.event.id}`}}>{this.props.event.id}</Link></span></p>
                      )}
                    <p>{this.props.event.popaplabeldate}</p> 
                    <p>{this.props.event.location}</p>
                </div>
                </Popup>
            </div>
        </>
        );
    }
}