import React from 'react';
import { Link } from 'react-router-dom';
import CustomButton from '../custom-button/custom-button.component';
import * as PropTypes from 'prop-types';


const UploadInvoiceButton = (props) => {
  return <div>
    <Link to={{
      pathname: "/" + props.taskId + "/subir-factura",
      query: {userData: props.userData, taskId: props.taskId, token: props.token, uploadToken: props.uploadToken}
    }}>
      <CustomButton
        className={"custom-button " +
        (props.invoice ? " green-button" : "")
        }
        onClick={props.onClick}
      >
        Factura
      </CustomButton>
    </Link>
  </div>;
};

UploadInvoiceButton.propTypes = {
  taskId: PropTypes.any,
  userData: PropTypes.any,
  token: PropTypes.any,
  uploadToken: PropTypes.any,
  documentConfirm: PropTypes.any,
  invoice: PropTypes.any,
  onClick: PropTypes.func
};

export default UploadInvoiceButton;
