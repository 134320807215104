import React from 'react';
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import Select from 'react-select';
import Pagination from 'react-paginating';
import LoadingSkeleton from '../loading-skeleton/loading-skeleton.component';

import './tasks.styles.scss'; 


class Tasks extends React.Component {

  signal = axios.CancelToken.source();

  constructor(props) {
    super(props);

    if(this.props.location.search == "" && JSON.parse(localStorage.getItem('task'))?.userData == null)
    {
      window.location.href = "http://localhost/app_dev.php/login";
    }

    this.state = {
      serviceData: [],
      userData: this.props.location.search ?
                this.props.location.search :
                JSON.parse(localStorage.getItem('task')).userData,
      redirect:    false,
      showFilters: false,
      filtersTitle: 'Ver Filtros',
      idInputValue: '',
      filteredArray: [],
      selectedStageOption: '',
      stageOptions: [],
      selectedDateOption: '',
      dateOptions: [],
      currentPage: 1,
      token: false,
      helperId: false
    };

    localStorage.setItem('task', JSON.stringify(this.state));

    this.handleFilters = this.handleFilters.bind(this);
  }

  controller = new AbortController();

  handlePageChange = (page, e) => {
    this.setState({
      currentPage: page
    });
  };

  decryptUserData() {
    const decryptedStr = this.handleHash(this.state.userData);
    const passwordArr = decryptedStr.split("password=");
    const pass = passwordArr[1];
    const usernameArr = passwordArr[0].split("username=");
    const user = usernameArr[1].slice(0,-1);

    return [user, pass];
  }

  handleHash(hash) {
    try {
      return atob(hash.substr(1));
    } catch (e) {
      this.setState({redirect: true});
    }
  }

  loginCheckAPI(urlLoginCheckAPI, userObject) {
    axios.post(urlLoginCheckAPI, userObject).then(
      result => {
        this.setState({token: result.data.token});

        const preparedConfig = {
          headers: {
            'Authorization': `Bearer ${this.state.token}`,
            'accept': 'application/json'
          },

        };

        const urlGetHelperId = process.env.REACT_APP_API_URL + 'api/fos_users?email=' + userObject.username;
        this.getHelperId(urlGetHelperId, preparedConfig);

      },
      error => {
        window.location.href = "https://multihelpers.com/login";
      }
    )
  }

  getHelperId(urlGetHelperId, preparedConfig) {
    axios.get(urlGetHelperId, preparedConfig).then(
      result => {
        this.setState({
          helperId: result.data[0].id
        });

        const urlIsHelperActiveCheckAPI = process.env.REACT_APP_API_URL + 'api/helpers/' + result.data[0].id + '/is_helper_active';
        this.getIsHelperActive(urlIsHelperActiveCheckAPI, preparedConfig);

        localStorage.setItem('taskList', JSON.stringify(this.state));
        const urlGetHelperTasks = process.env.REACT_APP_API_URL + 'api/helpers/' + result.data[0].id + '/services';

        this.getHelperTasks(urlGetHelperTasks, preparedConfig);

      }, error => {
        console.log(error);
        this.setState({redirect: true});
      }
    );
  }

  getIsHelperActive(urlIsHelperActiveCheckAPI, preparedConfig) {
    axios.get(urlIsHelperActiveCheckAPI, preparedConfig).then(
      result => {
        if(!result.data)
          this.setState({redirect: true});
      },
      error => {
        this.setState({redirect: true});
      }
    );
  }

  componentDidMount() {
    let secureArray = this.decryptUserData();
    const userObject = {
      username: secureArray[0],
      password: secureArray[1]
    };
    const urlLoginCheckAPI = process.env.REACT_APP_API_URL + 'login_check';

    this.loginCheckAPI(urlLoginCheckAPI, userObject, secureArray);
  }

  componentWillUnmount() {
    this.signal.cancel('API is being canceled');
    this.controller.abort();
  }

  getHelperTasks(urlGetHelperTasks, preparedConfig) {
    axios.get(urlGetHelperTasks, preparedConfig)
    .then(
      result => {
        this.setState({
          serviceData: result.data,
          filteredArray: this.paginatorNormalizer(result.data),
          stageOptions: this.stageOptionsNormalizer(result.data),
          dateOptions: this.dateOptionsNormalizer(result.data)
        });
      }, error => {
        this.setState({redirect: true});
      }
    )
  }

  goToTask(taskId) {
    window.location = '/' + taskId ;
  }
  goToVisit(taskId) {
    window.location = '/visita/' + taskId ;
  }

  createContext = (serviceDataArray) => {
    let context = [];

    for (let taskObj of serviceDataArray) {
      if(taskObj.taskStage == "VISITA")
      {
        context.push(
          <div className='task-list-row-visit' key={taskObj.id} onClick={ () => {this.goToVisit(taskObj.id)} }>
          <div className='task-list-col-1'>
            {taskObj.id}
          </div>

          <div className='task-list-col-2'>
            {taskObj.perfilHelperStage}
          </div>

          <div className='task-list-col-3'>
            {taskObj.visitDate}
          </div>
        </div>
        );
      }
      else{
      context.push(
        <div className='task-list-row' key={taskObj.id} onClick={ () => {this.goToTask(taskObj.id)} }>
          <div className='task-list-col-1'>
            {taskObj.id}
          </div>

          <div className='task-list-col-2'>
            {taskObj.perfilHelperStage}
          </div>

          <div className='task-list-col-3'>
            {taskObj.executionDate}
          </div>
        </div>
      );
      }
    }

    return context.reverse()
  };

  handleFilters = () => {
    const { showFilters } = this.state;
    this.setState({
      showFilters: !showFilters,
      filtersTitle: !this.state.showFilters ? 'Ocultar Filtros' : 'Ver Filtros'
    });
  };

  filterServiceId = (e) => {
    let currentList = [];
    let newList = [];

    if (e.target.value !== "") {
      let value = e.target.value;
      currentList = this.state.serviceData;

      newList = currentList.filter(item => {
        return String(item.id).includes(value);
      });
    } else {
      newList = this.state.serviceData;
    }
    this.setState({
      idInputValue: e.target.value,
      filteredArray: this.paginatorNormalizer(newList)
    });
  };

  filterStage = selectedStageOption => {
    let currentList = [];
    let newList = [];

    if (selectedStageOption !== '') {
      currentList = this.state.serviceData;
      newList = currentList.filter(item => {
        return item.perfilHelperStage === selectedStageOption.label
      });
    }

    this.setState({
      filteredArray: this.paginatorNormalizer(newList),
      selectedStageOption: selectedStageOption
    });
  };

  filterByDate = selectedDateOption => {
    let currentList = [];
    let newList = [];

    if (selectedDateOption !== '') {
      currentList = this.state.serviceData;
      newList = currentList.filter(item => {
        return item.executionDate === selectedDateOption.label
      });
    }

    this.setState({
      filteredArray: this.paginatorNormalizer(newList),
      selectedDateOption: selectedDateOption
    })
  };

  paginatorNormalizer = (serviceDataArr) => {
    let contextCopy = this.createContext(serviceDataArr);
    let paginatedContext = [];
    while (contextCopy.length > 0) {
      // TODO change delete count for the number of tasks per page
      let a = contextCopy.splice(0, 8);
      paginatedContext.push(a);
    }
    return paginatedContext;
  };

  stageOptionsNormalizer = serviceDataArr => {
    let resultArr = [];

    for (let elem of serviceDataArr) {
      resultArr.push({ value: elem.perfilHelperStage, label: elem.perfilHelperStage })
    }
    resultArr = this.removeArrDuplicates(resultArr, "value");

    return resultArr;
  };

  dateOptionsNormalizer = serviceDataArr => {
    let resultArray = [];
    for (let element of serviceDataArr) {
      resultArray.push({ value: element.executionDate, label: element.executionDate })
    }
    resultArray = this.removeArrDuplicates(resultArray, "value");

    return resultArray;
  };

  removeArrDuplicates = (originalArray, prop) => {
    let newArray = [];
    let lookupObject  = {};

    for(let i in originalArray) {
      lookupObject[originalArray[i][prop]] = originalArray[i];
    }
    for(let i in lookupObject) {
      newArray.push(lookupObject[i]);
    }

    return newArray;
  };

  resetFilters = () => {
    this.setState({
      idInputValue: '',
      selectedStageOption: '',
      selectedDateOption: '',
      filteredArray: this.paginatorNormalizer(this.state.serviceData)
    })
  };

  render() {
    if (this.state.redirect) {
      return ( <Redirect to={{ pathname: '/' }} /> );
    }

    if (typeof this.state.serviceData === 'undefined' || this.state.serviceData.length === 0) {
      return ( <LoadingSkeleton /> );
    }

    const limit = 2;
    const pageCount = 3;
    const { currentPage, showFilters, selectedStageOption, selectedDateOption } = this.state;
    const total = this.state.filteredArray.length * limit;

    return (
      <div>
        <span className='task-filter' onClick={this.handleFilters}>
          {this.state.filtersTitle}
        </span>
        {showFilters && (
          <div className='filter-section'>
            <div className='filter-by-id'>
              <label htmlFor='filterById'>ID servicio:&nbsp;</label>
              <input
                type='tel'
                pattern="^-?[0-9]\d*\.?\d*$"
                id='filterById'
                value={this.state.idInputValue}
                onChange={this.filterServiceId} />
            </div>
            <div className='filter-by-stage'>
              <label>Categoría:&nbsp;</label>
              <Select
                className='stage-select-container'
                classNamePrefix='stage-select'
                placeholder=''
                value={selectedStageOption}
                onChange={this.filterStage}
                options={this.state.stageOptions}
              />
            </div>
            <div className='filter-by-date'>
              <label>Fecha del servicio:&nbsp;</label>
              <Select
                className='date-select-container'
                classNamePrefix='date-select'
                placeholder=''
                value={selectedDateOption}
                onChange={this.filterByDate}
                options={this.state.dateOptions}
              />
            </div>

            <div className='reset-filters' onClick={this.resetFilters}>
              <span>Reestablecer filtros</span>
            </div>
          </div>
        )}
        <div>
          {this.state.filteredArray[this.state.currentPage - 1]}
        </div>

        <Pagination
          className="bg-mh"
          total={total}
          limit={limit}
          pageCount={pageCount}
          currentPage={currentPage}
        >
          {({
            pages,
            currentPage,
            hasNextPage,
            hasPreviousPage,
            previousPage,
            nextPage,
            totalPages,
            getPageItemProps
          }) => (
            <div>
              {hasPreviousPage && (
                <button className='paginating-button'
                  {...getPageItemProps({
                    pageValue: previousPage,
                    onPageChange: this.handlePageChange
                  })}
                >
                  {'<'}
                </button>
              )}

              {pages.map(page => {
                let activePage = null;
                if (currentPage === page) {
                  activePage = { backgroundColor: '#00cded', color: '#fff' };
                }
                if (this.state.filteredArray.length > 1) {
                  return (
                    <button className='paginating-button'
                      {...getPageItemProps({
                        pageValue: page,
                        key: page,
                        style: activePage,
                        onPageChange: this.handlePageChange
                      })}
                    >
                      {page}
                    </button>
                  );
                }
              })}

              {hasNextPage && (
                <button className='paginating-button'
                  {...getPageItemProps({
                    pageValue: nextPage,
                    onPageChange: this.handlePageChange
                  })}
                >
                  {'>'}
                </button>
              )}
            </div>
          )}
        </Pagination>

      </div>
    )
  }
}

export default Tasks;
