import React from 'react';

import './footer.styles.scss';
import LogoFooter from '../../assets/images/logo-mh-all-white.png';

const Footer = () => (
    <div className='footer'>
        <img className='logo-footer' src={ LogoFooter } alt="multihelpers" />
    </div>
);

export default Footer;
