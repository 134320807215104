import React from 'react';
import './available-task.styles.scss';
import axios from 'axios';
import { Redirect, Link} from 'react-router-dom';
import LoadingSkeleton from '../loading-skeleton/loading-skeleton.component';
import moment from 'moment';
import { faRubleSign } from '@fortawesome/free-solid-svg-icons';
import { GoCalendar, GoFile } from "react-icons/go";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowLeft} from '@fortawesome/free-solid-svg-icons';



class AvailableTasks extends React.Component {



signal = axios.CancelToken.source();

    constructor(props) {
      super(props);
  
      this.state = {
        serviceData: [],
        hasImages: false,
        thistaskId:   this.props.match.params.taskId,
        thisTaskcp: '',
        userData: this.props.location.search ?
                  this.props.location.search :
                  JSON.parse(localStorage.getItem('task')).userData,
        redirect:    false,
        showFilters: false,
        filtersTitle: 'Ver Filtros',
        idInputValue: '',
        thisClient:[],
        filteredArray: [],
        selectedStageOption: '',
        stageOptions: [],
        selectedDateOption: '',
        dateOptions: [],
        currentPage: 1,
        uploadToken: '',
        token: false,
        helperId: false,
        taskBudgetUrl: ''
      };
      
      localStorage.setItem('task', JSON.stringify(this.state));
    }
  
    controller = new AbortController();
  
    handlePageChange = (page, e) => {
      this.setState({
        currentPage: page
      });
    };
  
    decryptUserData() {
      const decryptedStr = this.handleHash(this.state.userData);
      const passwordArr = decryptedStr.split("password=");
      const pass = passwordArr[1];
      const usernameArr = passwordArr[0].split("username=");
      const user = usernameArr[1].slice(0,-1);
  
      return [user, pass];
    }
  
    handleHash(hash) {
      try {
        
        return atob(hash.substr(1));
      } catch (e) {
        this.setState({redirect: true});
      }
    }
  
    loginCheckAPI(urlLoginCheckAPI, userObject, secureArray) {
      axios.post(urlLoginCheckAPI, userObject).then(
        result => {
          this.setState({token: result.data.token});
  
          const preparedConfig = {
            headers: {
              'Authorization': `Bearer ${this.state.token}`,
              'accept': 'application/json'
            },
  
          };
  
          const urlGetHelperId = process.env.REACT_APP_API_URL + 'api/fos_users?email=' + userObject.username;
          this.getHelperId(urlGetHelperId, preparedConfig, secureArray);
  
        },
        error => {
          window.location.href = "https://multihelpers.com/login";
        }
      )
    }
  
    getHelperId(urlGetHelperId, preparedConfig, secureArray) {
      axios.get(urlGetHelperId, preparedConfig).then(
        result => {
          this.setState({
            helperId: result.data[0].id
          });

          const urlIsHelperActiveCheckAPI = process.env.REACT_APP_API_URL + 'api/helpers/' + this.state.helperId + '/is_helper_active';
          this.getIsHelperActive(urlIsHelperActiveCheckAPI, preparedConfig);
  
          localStorage.setItem('taskList', JSON.stringify(this.state));
          const urlGetHelperTasks = process.env.REACT_APP_API_URL + 'api/multihelpersjobs/' + this.state.thistaskId;
          const urlGetPassForFileUploader = process.env.REACT_APP_API_URL + 'api/fos_users/' + this.state.helperId + '/encrypted_password';
  
          this.getHelperTasks(urlGetHelperTasks, preparedConfig);
          this.getPassForFileUploader(urlGetPassForFileUploader, preparedConfig, secureArray);
        }, error => {
          console.log(error);
          this.setState({redirect: true});
        }
      );
    }

    getIsHelperActive(urlIsHelperActiveCheckAPI, preparedConfig) {
      axios.get(urlIsHelperActiveCheckAPI, preparedConfig).then(
        result => {
          if(!result.data)
            this.setState({redirect: true});
        },
        error => {
          this.setState({redirect: true});
        }
      );
    }

    getPassForFileUploader(urlGetPassForFileUploader, preparedConfig, secureArray) {
        axios.get(urlGetPassForFileUploader, preparedConfig).then(
          result => {
            let uploadToken = btoa(secureArray[0] + ":" + result.data.password);
            this.setState({
              uploadToken: uploadToken
            });
            localStorage.setItem('task', JSON.stringify(this.state));
          }, error => {
            console.log(error);
          }
        )
      }
  
    componentDidMount() {
      let secureArray = this.decryptUserData();
      
      const userObject = {
        username: secureArray[0],
        password: secureArray[1]
      };
      const urlLoginCheckAPI = process.env.REACT_APP_API_URL + 'login_check';
  
      this.loginCheckAPI(urlLoginCheckAPI, userObject, secureArray);
    }
  
    componentWillUnmount() {
      this.signal.cancel('API is being canceled');
      this.controller.abort();
    }
  
    getHelperTasks(urlGetHelperTasks, preparedConfig) {
      axios.get(urlGetHelperTasks, preparedConfig)
      .then(
        result => {
          this.setState({
            serviceData: result.data,
            hasImages: result.data.images.length
          });
          const urlGetTaskPostalCode = process.env.REACT_APP_API_URL + this.state.serviceData.postalcode.substr(1);
          const urlGetClient = process.env.REACT_APP_API_URL + this.state.serviceData.fosUser.substr(1);
          const urlGetbudget = process.env.REACT_APP_API_URL + 'api/multihelpersjobs/'+ this.state.thistaskId +'/budgets'
          
          this.getCp(urlGetTaskPostalCode, preparedConfig);
          this.getClient(urlGetClient, preparedConfig);
          this.getTaskBudget(urlGetbudget, preparedConfig);
          
        }, error => {
          this.setState({redirect: true});
        }
      )
    }

    getTaskBudget(urlGetbudget, preparedConfig){
        axios.get(urlGetbudget, preparedConfig)
      .then(
        result => {
          
          let favoriteBudget = result.data.filter(budget => budget.isFavorite);
          let documentBudget = favoriteBudget.filter(budget => budget.documentUrl);
          if(documentBudget && documentBudget.length){
            this.setState({
              taskBudgetUrl: documentBudget[0].documentUrl
            });
          } else {
            this.setState({
              taskBudgetUrl: 'SIN_PRESUPUESTO'
            });
          }
        }, error => {
          this.setState({redirect: true});
        }
      )
    }

    getCp(urlGetHelperTasks, preparedConfig) {
      axios.get(urlGetHelperTasks, preparedConfig)
      .then(
        result => {
          this.setState({
            thisTaskcp: result.data.cp,
            
          });          
        }, error => {
          this.setState({redirect: true});
        }
      )
    }

    getClient(urlGetHelperTasks, preparedConfig) {
      axios.get(urlGetHelperTasks, preparedConfig)
      .then(
        result => {
          this.setState({
            thisClient: result.data,
            
          });          
        }, error => {
          this.setState({redirect: true});
        }
      )
    }


    goToimages(taskId) {
      window.location = '/' + taskId +'/disponible/ver-fotos';
    }

    goToCalendar(taskId) {
      window.location = '/calendario';
    }

    goToAcceptCalendar(taskId) {
      window.location = '/' + taskId +'/calendario';
    }
  
  
    removeArrDuplicates = (originalArray, prop) => {
      let newArray = [];
      let lookupObject  = {};
  
      for(let i in originalArray) {
        lookupObject[originalArray[i][prop]] = originalArray[i];
      }
      for(let i in lookupObject) {
        newArray.push(lookupObject[i]);
      }
  
      return newArray;
    };

    forceDownload(url, fileName){
      var xhr = new XMLHttpRequest();
      xhr.open("GET", url, true);
      xhr.responseType = "blob";
      xhr.setRequestHeader('Authorization','Basic ' + this.state.userBasicToken);
      xhr.onload = function(){
          var urlCreator = window.URL || window.webkitURL;
          var imageUrl = urlCreator.createObjectURL(this.response);
          var tag = document.createElement('a');
          tag.href = imageUrl;
          tag.download = fileName;
          document.body.appendChild(tag);
          tag.click();
          document.body.removeChild(tag);
      }
      xhr.send();
    }
    downloadFileOverridingFilename(url, filename) {
      var xhr = new XMLHttpRequest();
      xhr.open('GET', url, true);
      xhr.responseType = 'blob';
      xhr.onload = function () {
          var blob = this.response;
          var a = document.createElement('a');
          a.href = window.URL.createObjectURL(blob);
          a.download = filename;
          a.dispatchEvent(new MouseEvent('click'));
      }
      xhr.send(null);
  }

    render() {
        if (this.state.redirect) {
          return ( <Redirect to={{ pathname: '/' }} /> );
        }
    
        if (typeof this.state.serviceData === 'undefined' || this.state.serviceData.length === 0) {
          return ( <LoadingSkeleton /> );
        }
        let uploadToken   = this.state.uploadToken;
        let token         = this.state.token;

        const task = this.state.serviceData;
        const client = {...this.state.thisClient};
        
        const myEvent = {
            id: this.state.thistaskId || '-',
            stage: task.taskStage || '-',
            descripcion: task.descripcion || '-',
            cp: this.state.thisTaskcp || '-',
            fechaEjecucion: task.executionDate,
          };
          

          myEvent.addressStreet = task.addressStreet || (task.addressStreet==0?0:'');
          myEvent.addressNumber = task.addressNumber || (task.addressNumber==0?0:'');
          myEvent.addressObservations = task.addressObservations || (task.addressObservations==0?0:'');
          myEvent.thisTaskcp = this.state.thisTaskcp || '-';
          myEvent.preLocation = (!myEvent.addressStreet && !myEvent.addressNumber && !myEvent.addressObservations)?'-':myEvent.addressStreet +' '+ myEvent.addressNumber + ' ' +myEvent.addressObservations;
          
          myEvent.location = myEvent.preLocation+ ' (' + myEvent.thisTaskcp + ')';
            
          myEvent.priceFinal = task.priceFinal || (task.priceFinal===0?0:'-');
          myEvent.priceHelper = task.priceHelper || (task.priceHelper===0?0:'-');
          myEvent.priceHelperWithoutIVA = Math.round(((myEvent.priceHelper / 1.21) + Number.EPSILON) * 100) / 100;
          if(myEvent.priceFinal == '-' || myEvent.priceHelper == '-'){
            myEvent.pricecomision = '-';
          } else{
            myEvent.pricecomision = task.priceFinal - task.priceHelper
          }
          
          if(!isNaN(parseFloat(task.priceFinal))) myEvent.priceFinal = parseFloat(task.priceFinal).toFixed(2);
          if(!isNaN(parseFloat(task.priceHelper))) myEvent.priceHelper = parseFloat(task.priceHelper).toFixed(2);
          if(!isNaN(parseFloat(myEvent.pricecomision))) myEvent.pricecomision = parseFloat(myEvent.pricecomision).toFixed(2);

      myEvent.executionDate = (task.executionDate && moment(task.executionDate, 'YYYY-MM-DDTHH:mm:ss').isValid())?moment(task.executionDate, 'YYYY-MM-DDTHH:mm:ss').format('DD/MM/YYYY HH:mm'):'-';
      myEvent.finishedAt = (task.finishedAt && moment(task.finishedAt, 'YYYY-MM-DDTHH:mm:ss').isValid())?moment(task.finishedAt, 'YYYY-MM-DDTHH:mm:ss').format('DD/MM/YYYY'):'-';
      myEvent.helperPaidAt = (task.helperPaidAt && moment(task.helperPaidAt, 'YYYY-MM-DDTHH:mm:ss').isValid())?moment(task.helperPaidAt, 'YYYY-MM-DDTHH:mm:ss').format('DD/MM/YYYY'):'-';
        
      

      client.billingFirstName = client.billingFirstName || '-';
      client.billingLastName = client.billingLastName || '-';
      client.uin = client.uin || '-';
      client.billingAddress = client.billingAddress || '-';
      
      return (
        <div className="main-container">
          <Link className='goBack'
                  to={{ pathname: '/servicios_disponibles', token: this.state.token, helperId: this.state.helperId }} >
              <span><FontAwesomeIcon icon={ faArrowLeft } /></span>
          </Link>
          <div className="ficha-container">
              <div className="ficha-task">
                  <div className="ficha-header-title">ID {myEvent.id}</div>
              </div>
              <div className="ficha-servicio">
                  <div className="ficha-title">Descripción del servicio</div>
                  <div>{myEvent.descripcion}</div>
                  <div className="ficha-servicio-footer">
                      <div className="ficha-bold">Código postal {myEvent.cp}</div>
                      
                      {this.state.hasImages?(
                        <a onClick={ () => { this.goToimages(this.state.thistaskId)} } href="#">Ver fotografías</a>
                      ):(
                        <div class="link-noimages">No hay fotografías</div>
                      )}
                      <div className="ficha-btn-presupuesto">
                      {this.state.taskBudgetUrl && this.state.taskBudgetUrl != "SIN_PRESUPUESTO" ? (
                            // 
                            <a href="#" onClick={() => this.downloadFileOverridingFilename(this.state.taskBudgetUrl, `${this.state.thistaskId}_${moment().format("DD-MM-YYYY")}_Presupuesto.pdf`)} >Presupuesto aceptado <GoFile className="documenticon-si"/> </a>
                            // <a href="#" onClick={() => this.downloadFile(this.state.taskBudgetUrl, `${this.state.thistaskId}_${moment().format("DD-MM-YYYY")}_Factura.pdf`)} >Presupuesto aceptado <GoFile className="documenticon-si"/> </a>
                            // <a dowload={`${this.state.thistaskId}_${moment().format("DD-MM-YYYY")}_Factura.pdf`} filename={`${this.state.thistaskId}_${moment().format("DD-MM-YYYY")}_Factura.pdf`} href={this.state.taskBudgetUrl} >Presupuesto aceptado <GoFile className="documenticon-si"/> </a>
                          ) : this.state.taskBudgetUrl != "SIN_PRESUPUESTO"?(
                            <div>Localizando presupuesto... <GoFile className="documenticon-no"/> </div>
                          ):(
                            <div>Sin presupuesto <GoFile className="documenticon-no"/> </div>
                          )
                          
                          }
                          
                      </div>
                  </div>
              </div>
              <div className="ficha-facturacion-contenedor">
                  <div className="ficha-title">Facturación</div>
                  <div className="ficha-facturacion-contenedor-bloque">
                    <div>Importe total Factura: {myEvent.priceHelperWithoutIVA}€ + IVA</div>
                  </div>                
              </div>  
              <div className="ficha-facturacion-contenedor">
                <button className="custom-button ficha-button-documentos" onClick={ () => {this.goToAcceptCalendar(this.state.thistaskId)} }>Aceptar servicio</button>  
              </div>          
            </div>
          </div>
      )
    }
}

  
export default AvailableTasks;

