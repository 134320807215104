import React from 'react';
import CustomButton from '../custom-button/custom-button.component';
import * as PropTypes from 'prop-types';


const ConfirmityDocButton = (props) => {
  return <div>
    <a href={props.href}>
      <CustomButton
        className={"custom-button " +
          (props.photosAfter ? " " : "grey-button ") +
          (props.documentConfirm ? " green-button" : "")
        }
        onClick={props.onClick}
      >
        Documento conformidad
      </CustomButton>
    </a>
  </div>;
};

ConfirmityDocButton.propTypes = {
  href: PropTypes.string,
  photosAfter: PropTypes.any,
  documentConfirm: PropTypes.any,
  onClick: PropTypes.func
};

export default ConfirmityDocButton;
