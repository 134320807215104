import React from 'react';
import Header from '../../components/header/header.component';
import Footer from '../../components/footer/footer.component';
import CalendarComponent from '../../components/calendar/taskcalendar.component';
import './task-calendar.styles.scss';
import 'react-big-calendar/lib/css/react-big-calendar.css'



const TaskCalendar = (props) => {
  return (
    <div className='parent'>
      <Header /> 
      <div className='task-list'>
    
        <h3>Calendario</h3>

        <CalendarComponent {...props} className="calendario"/>

      </div>

      <Footer />
    </div>
  )
};

export default TaskCalendar;
