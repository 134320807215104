import React from 'react';
import './accepttaskcalendar.styles.scss';
import { Calendar, Views, momentLocalizer } from 'react-big-calendar'
import axios from 'axios';
import { Redirect, Link, StaticRouter } from 'react-router-dom';
import LoadingSkeleton from '../loading-skeleton/loading-skeleton.component';
import moment from 'moment';
import { faRubleSign, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import DayEvent from './dayevent.component';
import MonthEvents from './monthevents.components';
import WeekEvents from './weekevents.components';
import { modalview } from 'react-ga';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import PopupMessage from '../../components/popup-message/popup-message.component';
import PopupConfirm from '../../components/popup-message/popup-confirm.component';
import { GoArrowLeft } from "react-icons/go";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowLeft} from '@fortawesome/free-solid-svg-icons';
import { Alert } from 'react-bootstrap';
import { Input } from 'react-rainbow-components';



class taskcalendar extends React.Component {

    signal = axios.CancelToken.source();

    constructor(props) {
      super(props);
  
      this.state = {
        serviceData: [],
        visitsData:[],
        tasksData:[],
        thistaskId:   this.props.match.params.taskId,
        userData: this.props.location.search ?
                  this.props.location.search :
                  JSON.parse(localStorage.getItem('task')).userData,
        redirect:    false,
        showFilters: false,
        filtersTitle: 'Ver Filtros',
        idInputValue: '',
        filteredArray: [],
        myEvents:[],
        selectedStageOption: '',
        stageOptions: [],
        selectedDateOption: '',
        dateOptions: [],
        currentPage: 1,
        token: false,
        helperId: false,
        thisTask:[],
        contador: 0,
        executionDateOption1: '',
        executionDateOption2: '',
        executionDateOption3: '',
        showModal: false,
        inactiveDays : [
          '12-10-2021',
          '01-11-2021',
          '06-12-2021',
          '08-12-2021',
          '25-12-2021',
          '01-01-2022',
          '06-01-2022',
          '12-10-2022',
          '01-11-2022',
          '06-12-2022',
          '08-12-2022',
          '05-12-2022' 
        ],
        showModalInicio: true
      };
      
      localStorage.setItem('task', JSON.stringify(this.state));
      this.handleOpenModal = this.handleOpenModal.bind(this);
      this.handleCloseModalInicio = this.handleCloseModalInicio.bind(this)
      this.handleCloseModal = this.handleCloseModal.bind(this);
      
      //this.handleFilters = this.handleFilters.bind(this);
    }
  
    controller = new AbortController();
  
    handlePageChange = (page, e) => {
      this.setState({
        currentPage: page
      });
    };

    handleOpenModal () {
      setTimeout(() => {
        this.setState({ showModal: true });  
      }, 400);
    }

    handleCloseModalInicio(){
      this.setState({ showModalInicio: false });
    }
    
    handleCloseModal () {
      this.setState({ showModal: false });

      const preparedConfig = {
        headers: {
          'Authorization': 'Bearer ' + this.state.token,
          'accept': 'application/json'
        }
      };
      const urlGetJobsHelperId = process.env.REACT_APP_API_URL + 'api/multihelpersjobs/'+ this.state.thistaskId +'/is_helper_id';
      axios.get(urlGetJobsHelperId, preparedConfig).then(
        result => {
          
          const actualHelper = result.data;

          if(!actualHelper || actualHelper == this.state.helperId.toString()){
            const url = process.env.REACT_APP_API_URL + 'api/multihelpersjobs/' + this.state.thistaskId;
            let dataJSON = {
              executionDateOption1: this.state.executionDateOption1,
              executionDate: this.state.executionDateOption1,
              executionHour: this.state.executionDateOption1,
              executiondateselected: '1',
              isResolutionDataSentToHelper: true,
              helperUser: '/api/fos_users/' + this.state.helperId.toString(),
              helperAsignedAutomatically: true,
              taskStage: 'TAREA EN PROCESO',
              isResolutionDataSentToAdvertiser: false,
              isResolutionDataSentToHelper: false,        
              isHelperResolutionDataToHelperSent: false,
              isClientResolutionDataToAdvertiserSent: false,        
            };
            
            axios.put(url, dataJSON, preparedConfig).then(
              result => {
                const urlSendResolutionDataToHelper = process.env.REACT_APP_API_URL + 'pubreminders/helper_send_resolution_data_to_helper';
                const urlsendResolutionDataToAdvertiser = process.env.REACT_APP_API_URL + 'pubreminders/client_send_resolution_data_to_advertiser';
                const urlSendResolutionDataToAdvertiserBySirena = process.env.REACT_APP_API_URL + 'pubreminders/sirena_send_resolution_data_to_client/' + this.state.thistaskId;
                
                var sendResolutionDataToAdvertiserBySirena = axios.post(urlSendResolutionDataToAdvertiserBySirena, null, preparedConfig);
                var sendResolutionDataToHelper = axios.post(urlSendResolutionDataToHelper, null, preparedConfig);
                var sendResolutionDataToAdvertiser = axios.post(urlsendResolutionDataToAdvertiser, null, preparedConfig);

                // this.sendResolutionDataToHelper(urlSendResolutionDataToHelper, preparedConfig);
                // this.sendResolutionDataToAdvertiser(urlsendResolutionDataToAdvertiser, preparedConfig);
                axios.all([sendResolutionDataToHelper, sendResolutionDataToAdvertiser, sendResolutionDataToAdvertiserBySirena]).then(
                    axios.spread((...responses) => {
                      const responseSendResolutionDataToHelper = responses[0];
                      const responseSendResolutionDataToAdvertiser = responses[1];
                      const sendResolutionDataToAdvertiserBySirena = responses[2];
                      PopupMessage('success','Has aceptado la tarea', "none", 'calendario');
                  })).catch(errors => {
                      PopupMessage('error','Algo no va bien', '', this.state.thistaskId +'/calendario');
                });
                
              }, error => {
                PopupMessage('error','Algo no va bien', '', this.state.thistaskId +'/calendario');
              }
            );
          }
          else{
            PopupMessage('error','No puedes aceptar este servicio porque ya ha sido aceptado por otro Helper', '', '/servicios');
          }
        }, error => {
          PopupMessage('error','Algo a salido mal', '', '/calendario');
        }
      );
    }
  
    decryptUserData() {
      const decryptedStr = this.handleHash(this.state.userData);
      const passwordArr = decryptedStr.split("password=");
      const pass = passwordArr[1];
      const usernameArr = passwordArr[0].split("username=");
      const user = usernameArr[1].slice(0,-1);
  
      return [user, pass];
    }

    goBack(taskId){
      window.location = '/' + taskId +'/calendario';
    }

    dateFormat(date){
        return date?moment(date, 'DD-MM-YYYY HH:mm:ss').format('dddd D MMM HH.mm[h]'):'-'
    }
  
    handleHash(hash) {
      try {
        
        return atob(hash.substr(1));
      } catch (e) {
        this.setState({redirect: true});
      }
    }
  
    loginCheckAPI(urlLoginCheckAPI, userObject) {
      axios.post(urlLoginCheckAPI, userObject).then(
        result => {
          this.setState({token: result.data.token});
  
          const preparedConfig = {
            headers: {
              'Authorization': `Bearer ${this.state.token}`,
              'accept': 'application/json'
            },
  
          };
  
          const urlGetHelperId = process.env.REACT_APP_API_URL + 'api/fos_users?email=' + userObject.username;
          this.getHelperId(urlGetHelperId, preparedConfig);
  
        },
        error => {
          window.location.href = "https://web.dev.multihelpers.net/login";
        }
      )
    }
  
    // sendResolutionDataToHelper(urlSendResolutionDataToHelper, preparedConfig) {
    //   axios.post(urlSendResolutionDataToHelper,null,preparedConfig).then(
    //     result => {
    //       console.log('Exito send Resolution Data to helper');
    //     },
    //     error => {
    //       console.log('error send Resolution Data to helper');
    //     }
    //   );
    // }

    // sendResolutionDataToAdvertiser(urlsendResolutionDataToAdvertiser, preparedConfig) {
    //   axios.post(urlsendResolutionDataToAdvertiser,null, preparedConfig).then(
    //     result => {
    //       console.log('Exito send Resolution Data to Advenrtiser');
    //     },
    //     error => {
    //       console.log('error send Resolution Data to Advenrtiser');
    //     }
    //   );
    // }

    getHelperId(urlGetHelperId, preparedConfig) {
      axios.get(urlGetHelperId, preparedConfig).then(
        result => {
          this.setState({
            helperId: result.data[0].id
          });

          const urlIsHelperActiveCheckAPI = process.env.REACT_APP_API_URL + 'api/helpers/' + result.data[0].id + '/is_helper_active';
          this.getIsHelperActive(urlIsHelperActiveCheckAPI, preparedConfig);
  
          localStorage.setItem('taskList', JSON.stringify(this.state));
          const urlGetHelperTasks = process.env.REACT_APP_API_URL + 'api/helpers/' + result.data[0].id + '/services';
  
          this.getHelperTasks(urlGetHelperTasks, preparedConfig);
  
        }, error => {
          console.log(error);
          this.setState({redirect: true});
        }
      );
    }

    getIsHelperActive(urlIsHelperActiveCheckAPI, preparedConfig) {
      axios.get(urlIsHelperActiveCheckAPI, preparedConfig).then(
        result => {
          if(!result.data)
            this.setState({redirect: true});
        },
        error => {
          this.setState({redirect: true});
        }
      );
    }
  
    componentDidMount() {
      
      let secureArray = this.decryptUserData();
      
      const userObject = {
        username: secureArray[0],
        password: secureArray[1]
      };
      const urlLoginCheckAPI = process.env.REACT_APP_API_URL + 'login_check';
      this.loginCheckAPI(urlLoginCheckAPI, userObject, secureArray);

      
      
      
    }
  
    componentWillUnmount() {
      this.signal.cancel('API is being canceled');
      this.controller.abort();
    }
  
    getHelperTasks(urlGetHelperTasks, preparedConfig) {
      axios.get(urlGetHelperTasks, preparedConfig)
      .then(
        result => {
          this.setState({
            serviceData: result.data,
          });
          let taskList = [];

          this.state.serviceData.forEach(taskObj => {
            if(!taskObj.addressObsevations){taskObj.addressObsevations = ''}
            if(taskObj.isResolutionDataSentToHelper || taskObj.isResolutionDataSentToAdvertiser){
              taskList.push({
                id: taskObj.id,
                isNew: false,
                title: '' + taskObj.id,
                allDay: false,
                isVisit: false,
                start: new Date(taskObj.executionDatetimeUS),
                end: new Date(taskObj.executionDatetimeUS),
                daylabel: 'ID ' + taskObj.id + ' ' + taskObj.executionDatetime,
                popaplabeldate: taskObj.executionDatetime,
                location: taskObj.addressStreet +' '+ taskObj.addressNumber + ' ' +taskObj.addressObsevations+ '(' + taskObj.postalcode + ')',
                perfilHelperStage: taskObj.perfilHelperStage
              });
            }
          });
          this.setState({tasksData: taskList});

          const urlGetHelperTasks = process.env.REACT_APP_API_URL + 'api/multihelpersjobs/'+ this.state.thistaskId ;
          const urlGetHelperVisits = process.env.REACT_APP_API_URL + 'api/helpers/' + this.state.helperId + '/visits'; 

          this.getHelpervisits(urlGetHelperVisits, preparedConfig);
          this.getavaliableTask(urlGetHelperTasks, preparedConfig);

        }
      )
    }

    getHelpervisits(urlGetHelperVisits, preparedConfig) {
      axios.get(urlGetHelperVisits, preparedConfig)
      .then(
        result => {
          this.helperVisitsHandleResponse(result);
        }
      ).catch(
        error=>{
          if(error?.response?.status == 404){
            this.helperVisitsHandleResponse();

          }
        }
      );
    }


    helperVisitsHandleResponse(result){
      let visitList = [];
          for(let visitObj of (result?.data || [])) {
            if(!visitObj.addressObsevations){visitObj.addressObsevations = ''}
            if(visitObj.isVisitDataSentToHelper)
              visitList.push({
              id: visitObj.id,
              isVisit: true,
              title: '' + visitObj.id,
              allDay: false,
              start: new Date(visitObj.visitDatetimeUS + ' ' + visitObj.visitTime),
              end: new Date(visitObj.visitDatetimeUS + ' ' + visitObj.visitTime),
              daylabel: 'VISITA ID ' + visitObj.id + ' ' + visitObj.visitDate+' ' + visitObj.visitTime,
              popaplabeldate: visitObj.visitDatetimeUS + ' ' + visitObj.visitTime,
              location: visitObj.addressStreet +' '+ visitObj.addressNumber + ' ' +visitObj.addressObsevations+ '(' + visitObj.postalcode + ')',
              perfilHelperStage: visitObj.perfilHelperStage
              });
          }
          this.setState({
            visitsData: visitList,
          });
          this.state.visitsData.forEach(visit =>{
            this.state.tasksData.push(visit);
          });
          this.setState({myEvents: this.state.tasksData});
    }

    getavaliableTask(urlGetHelperTasks, preparedConfig){
      axios.get(urlGetHelperTasks, preparedConfig)
      .then(
        result => {
          this.setState({
            thisTask: result.data,
            executionDateOption2: result.data.executionDateOption2,
            executionDateOption3: result.data.executionDateOption3
          });
          console.log(this.state);
        }, error => {
          this.setState({redirect: true});
        }
      )
    }

    handleAceptarFechaCliente2 = ()=>{
      this.setState({executionDateOption1: this.state.executionDateOption2});
      this.aceptarFecha();
    }

    handleAceptarFechaCliente3 = ()=>{
      this.setState({executionDateOption1: this.state.executionDateOption3});
      this.aceptarFecha();
    }

    aceptarFecha = ()=>{

      this.setState({ showModal: false });

      const preparedConfig = {
        headers: {
          'Authorization': 'Bearer ' + this.state.token,
          'accept': 'application/json'
        }
      };
      const urlGetJobsHelperId = process.env.REACT_APP_API_URL + 'api/multihelpersjobs/'+ this.state.thistaskId +'/is_helper_id';
      axios.get(urlGetJobsHelperId, preparedConfig).then(
        result => {
          
          const actualHelper = result.data;

          if(!actualHelper || actualHelper == this.state.helperId.toString()){
            const url = process.env.REACT_APP_API_URL + 'api/multihelpersjobs/' + this.state.thistaskId;
            let dataJSON = {
              executionDateOption1: this.state.executionDateOption1,
              executionDate: this.state.executionDateOption1,
              executionHour: this.state.executionDateOption1,
              executiondateselected: '1',
              isResolutionDataSentToHelper: true,
              helperUser: '/api/fos_users/' + this.state.helperId.toString(),
              helperAsignedAutomatically: true,
              taskStage: 'TAREA EN PROCESO',
              isResolutionDataSentToAdvertiser: false,
              isResolutionDataSentToHelper: false,        
              isHelperResolutionDataToHelperSent: false,
              isClientResolutionDataToAdvertiserSent: false,        
            };
            
            axios.put(url, dataJSON, preparedConfig).then(
              result => {
                const urlSendResolutionDataToHelper = process.env.REACT_APP_API_URL + 'pubreminders/helper_send_resolution_data_to_helper';
                const urlsendResolutionDataToAdvertiser = process.env.REACT_APP_API_URL + 'pubreminders/client_send_resolution_data_to_advertiser';
                const urlSendResolutionDataToAdvertiserBySirena = process.env.REACT_APP_API_URL + 'pubreminders/sirena_send_resolution_data_to_client/' + this.state.thistaskId;
                
                var sendResolutionDataToAdvertiserBySirena = axios.post(urlSendResolutionDataToAdvertiserBySirena, null, preparedConfig);
                var sendResolutionDataToHelper = axios.post(urlSendResolutionDataToHelper, null, preparedConfig);
                var sendResolutionDataToAdvertiser = axios.post(urlsendResolutionDataToAdvertiser, null, preparedConfig);

                // this.sendResolutionDataToHelper(urlSendResolutionDataToHelper, preparedConfig);
                // this.sendResolutionDataToAdvertiser(urlsendResolutionDataToAdvertiser, preparedConfig);
                axios.all([sendResolutionDataToHelper, sendResolutionDataToAdvertiser, sendResolutionDataToAdvertiserBySirena]).then(
                    axios.spread((...responses) => {
                      const responseSendResolutionDataToHelper = responses[0];
                      const responseSendResolutionDataToAdvertiser = responses[1];
                      const sendResolutionDataToAdvertiserBySirena = responses[2];
                      PopupMessage('success','Has aceptado la tarea', "none", 'calendario');
                  })).catch(errors => {
                      PopupMessage('error','Algo no va bien', '', this.state.thistaskId +'/calendario');
                });
                
              }, error => {
                PopupMessage('error','Algo no va bien', '', this.state.thistaskId +'/calendario');
              }
            );
          }
          else{
            PopupMessage('error','No puedes aceptar este servicio porque ya ha sido aceptado por otro Helper', '', '/servicios');
          }
        }, error => {
          PopupMessage('error','Algo a salido mal', '', '/calendario');
        }
      );
    
    }
    
  
    removeArrDuplicates = (originalArray, prop) => {
      let newArray = [];
      let lookupObject  = {};
  
      for(let i in originalArray) {
        lookupObject[originalArray[i][prop]] = originalArray[i];
      }
      for(let i in lookupObject) {
        newArray.push(lookupObject[i]);
      }
  
      return newArray;
    };

    handleSlot = date => {
        let unixDate = moment(date).format('x');
        if(this.state.inactiveDays.includes(moment(date).format('DD-MM-YYYY'))){
          return { className: 'calendar-slot-lock ' + unixDate };
        }
        else if (moment(date).isAfter()  && moment(date).isoWeekday() != 7 && moment(date).format("H") >= 9 && ((moment(date).isoWeekday() <=5 && moment(date).format("H") <= 19))){
          return { className: 'calendar-slot-available ' + unixDate }
        }
      return { className: 'calendar-slot-lock ' + unixDate };
    };


    navigate = () => {
      const slots = document.getElementsByClassName("rbc-time-slot calendar-slot-available");
      const fnHandleSelect = this.handleSelect;
        setTimeout(() => {
            for (let i = 0; i < slots.length; i++) {
                const el = slots[i];
                let onClickSlot = e => {
                  
                  let element = e.target;
                  if(element.className == 'rbc-label') element = e.target.parentElement;
                    // let time = moment.utc(moment.duration((i-4) / 2, 'hours').asMilliseconds()).format('HH:mm:ss');
                    let time = element.classList[element.classList.length -1];
                    let dateObj = moment(time, 'x').toDate();
                    fnHandleSelect(dateObj);
                };  
                el.onclick = onClickSlot;
              }
        });
    } 

    handleSelect = date => {

      
      if (!this.state.inactiveDays.includes(moment(date).format('DD-MM-YYYY')) && moment(date).isAfter()  && moment(date).isoWeekday() != 7 && moment(date).format("H") >= 9 && ((moment(date).isoWeekday() <=5 && moment(date).format("H") <= 19)))
      {
        
      
          const title = "+"
          this.setState({
            myEvents: [
              ...this.state.myEvents,
              {
                start: date,
                end: date,
                title,
                isNew: true
              },
            ],
          });
          
            this.state.executionDateOption1 = moment(date).format('DD-MM-YYYY HH:mm:ss');
            this.handleOpenModal();
          
          
      }
      };
  
    render() {
      if (this.state.redirect) {
        return ( <Redirect to={{ pathname: '/' }} /> );
      }
  
      if (typeof this.state.serviceData === 'undefined') {
        return ( <LoadingSkeleton /> );
      }


      const MonthEvent = ({ event }) => (
        <div>
          <div>{event.title}</div>
        </div>
      );
        

      require('moment/locale/es.js');
      const mylocalizer = momentLocalizer(moment);
            
            
      return (
        <div>
          <Link className='goBack'
              to={{ pathname: '/'+this.state.thistaskId+'/disponible', token: this.state.token, helperId: this.state.helperId }} >
          <span><FontAwesomeIcon icon={ faArrowLeft } /></span>
        </Link>
          <Modal 
          ariaHideApp={false}
           isOpen={this.state.showModalInicio}
           contentLabel="Minimal Modal Example"
           className="modal-init"
           >
           
             <div><span className="bold">Fechas de cliente</span></div>
             {this.state.executionDateOption2 &&
             <div className="datetime-container">
                <Input
                  value={moment(this.state.executionDateOption2).format("ddd D/MM/YYYY HH:mm")}
                  readOnly = {true}
                  type="datetime"
                  className="rainbow-p-around_medium"
                />
                <button className="custom-button modal-button-documentos" onClick={this.handleAceptarFechaCliente2}>Aceptar fecha</button>
             </div>}
             {this.state.executionDateOption3 &&
             <div className="datetime-container">
              <Input 
                  value={moment(this.state.executionDateOption3).format("ddd D/MM/YYYY HH:mm")}
                  readOnly = {true}
                  type="datetime"
                  className="rainbow-p-around_medium"
              />
              <button className="custom-button modal-button-documentos" onClick={this.handleAceptarFechaCliente3}>Aceptar fecha</button>
            </div>}
             <div>En Caso de que no puedas adaptarte a las horas del cliente, propón tu la hora.</div>
            <button className="custom-button modal-button-documentos" onClick={this.handleCloseModalInicio}>Proponer fechas</button>
          </Modal>

          <Calendar
            localizer={mylocalizer}
            events={this.state.myEvents}
            startAccessor="start"
            timeslots={1}
            onView={this.navigate}
            longPressThreshold={250000000}
            views={{month: true, week: true, day: true}}
            drilldownView="day"
            onNavigate = {this.navigate}
            onSelectEvent={this.handleEventClick}
            onSelectSlot={this.handleSelect}
            slotPropGetter={this.handleSlot}
            eventPropGetter={
              (event, start, end, isSelected) => {
                let newStyle = {
                  backgroundColor: "rgb(0, 205, 237)",
                  color: 'white',
                };
          
                if (event.perfilHelperStage == "Pendiente de pago"){
                  newStyle.backgroundColor = "#ffbd59";
                  newStyle.color = "white";
                }else if(event.perfilHelperStage == "Finalizado"){
                  newStyle.backgroundColor = "#8cb70d";
                  newStyle.color = "white";
                }else{
                  newStyle.backgroundColor = "#00cded";
                  newStyle.color = "white";
                }

                if (!event.id){
                  newStyle.backgroundColor = "#8bc34a";
                  newStyle.color = "white";
                }
          
                return {
                  className: "",
                  style: newStyle
                };
              }
            }
            popup={true}
            messages={{
              next: "sig",
              previous: "ant",
              today: "Hoy",
              month: "Mes",
              week: "Semana",
              day: "Día",
              showMore: total => (
                <div
                  style={{ cursor: 'pointer' }}
                  onMouseOver={e => {
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                >{`+${total} más`}
                </div>
              ),
 
            }}
            components={{
              day:{event: DayEvent,},
              month:{event: MonthEvents,},
              week:{event: WeekEvents,},
            }}
          />
           <Modal 
           ariaHideApp={false}
           className="modal-init"
           isOpen={this.state.showModal}
           contentLabel="Minimal Modal Example">
            <span><FontAwesomeIcon className="icon-back" icon={ faArrowLeft } onClick={ () => {this.goBack(this.state.thistaskId,)} }/></span>
            <div className="modal-propouse-text"> Estas a punto de proponer al cliente la siguientes fechas para realizar el servicio <span className="bold">{this.state.thistaskId}</span>:</div>
            <div className="modal-propouse-dates">
                <div>- {this.dateFormat(this.state.executionDateOption1)}</div>
            </div>
            <button className="custom-button modal-button-documentos" onClick={this.handleCloseModal}>Aceptar</button>
          </Modal>
          
        </div>
      )
    }
}

  
export default taskcalendar;