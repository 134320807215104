import React from 'react';
import axios from 'axios';
import {Link, Redirect} from 'react-router-dom';

import TaskTitle from '../../components/task-title/task-title.component';
import UploadPhotosButton from '../../components/action-buttons/upload-photos-button.component'
import LoadingSkeleton from '../../components/loading-skeleton/loading-skeleton.component';
import PopupMessage from '../../components/popup-message/popup-message.component';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowLeft} from '@fortawesome/free-solid-svg-icons';
import './visit-profile.styles.scss';


class VisitProfile extends React.Component {

  signal = axios.CancelToken.source();

  constructor(props) {
    super(props);

    this.state = {
      taskId:   this.props.match.params.taskId,
      userData: this.props.location.search ?
                this.props.location.search :
                JSON.parse(localStorage.getItem('task')).userData,
      isPhotosBefore: null,
      token: null,
      helperId: null,
      uploadToken: null,
      redirect: false
    };

    localStorage.setItem('task', JSON.stringify(this.state));
  }

  controller = new AbortController();

  stopPreviousEvents(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  handleHash(hash) {
    try {
      return atob(hash.substr(1));
    } catch (e) {
      this.setState({redirect: true});
    }
  }


  handleOnPhotosAfterClick(e) {
    if(!this.state.isPhotosBefore) {
        this.stopPreviousEvents(e);
        PopupMessage('warning', 'Antes debes subir las fotos de antes del servicio', 'none');
    }
  }

  decryptUserData() {
    const decryptedStr = this.handleHash(this.state.userData);
    const passwordArr = decryptedStr.split("password=");
    const pass = passwordArr[1];
    const usernameArr = passwordArr[0].split("username=");
    const user = usernameArr[1].slice(0,-1);

    return [user, pass];
  }

  getPendingDocs(urlPendingDocs, preparedConfig) {
    axios.get(urlPendingDocs, preparedConfig).then(
      result => {
        this.setState({
          isPhotosBefore:    result.data.areAnyImageFromBefore,
        });
        localStorage.setItem('task', JSON.stringify(this.state));
      }, error => {
        console.log(error);
      }
    );
  }

  getPassForFileUploader(urlGetPassForFileUploader, preparedConfig, secureArray) {
    axios.get(urlGetPassForFileUploader, preparedConfig).then(
      result => {
        let uploadToken = btoa(secureArray[0] + ":" + result.data.password);
        this.setState({
          uploadToken: uploadToken
        });
        localStorage.setItem('task', JSON.stringify(this.state));
      }, error => {
        console.log(error);
      }
    )
  }

  getHelperId(urlGetHelperId, preparedConfig, secureArray) {
    axios.get(urlGetHelperId, preparedConfig).then(
      result => {
        this.setState({
          helperId: (result.data).match(/\d+$/)[0]
        });

        const urlIsHelperActiveCheckAPI = process.env.REACT_APP_API_URL + 'api/helpers/' + this.state.helperId + '/is_helper_active';
        this.getIsHelperActive(urlIsHelperActiveCheckAPI, preparedConfig);

        localStorage.setItem('task', JSON.stringify(this.state));
        const urlGetPassForFileUploader = process.env.REACT_APP_API_URL + 'api/fos_users/' + this.state.helperId + '/encrypted_password';

        this.getPassForFileUploader(urlGetPassForFileUploader, preparedConfig, secureArray);
      }, error => {
        console.log(error);
      }
    );
  }

  getIsHelperActive(urlIsHelperActiveCheckAPI, preparedConfig) {
    axios.get(urlIsHelperActiveCheckAPI, preparedConfig).then(
      result => {
        if(!result.data)
          this.setState({redirect: true});
      },
      error => {
        this.setState({redirect: true});
      }
    );
  }

  loginCheckAPI(urlLoginCheckAPI, userObject, secureArray) {
    axios.post(urlLoginCheckAPI, userObject, {cancelToken: this.signal.token}).then(
      result => {
        this.setState({token: result.data.token});
        localStorage.setItem('task', JSON.stringify(this.state));

        const urlPendingDocs = process.env.REACT_APP_API_URL + 'api/multihelpersjobs/' + this.state.taskId + '/pending_documentation';
        const preparedConfig = {
          headers: {
            'Authorization': `Bearer ${this.state.token}`,
            'accept': 'application/json'
          },
          cancelToken: this.signal.token
        };

        this.getPendingDocs(urlPendingDocs, preparedConfig);
        const urlGetHelperId = process.env.REACT_APP_API_URL + 'api/multihelpersjobs/' + this.state.taskId + '/helper_id';
        this.getHelperId(urlGetHelperId, preparedConfig, secureArray);
      },
      error => {
        window.location.href = "https://multihelpers.com/login";
      }
    )
  }

  componentDidMount() {
    let secureArray = this.decryptUserData();
    const userObject = {
      username: secureArray[0],
      password: secureArray[1]
    };
    const urlLoginCheckAPI = process.env.REACT_APP_API_URL + 'login_check';

    this.loginCheckAPI(urlLoginCheckAPI, userObject, secureArray);
  }

  componentWillUnmount() {
    this.signal.cancel('API is being canceled');
    this.controller.abort();
  }

  render() {
    let taskId = this.state.taskId;
    let validatedTaskId = taskId.match(/^[0-9]{5}$/gi);

    if (!validatedTaskId || this.state.redirect) {
      return (
        <Redirect to={{ pathname: '/' }} />
      );
    }

    if (this.state.isInvoice === null || this.state.uploadToken === null) return (<LoadingSkeleton/>);

    let userData      = this.state.userData;
    let uploadToken   = this.state.uploadToken;
    let token         = this.state.token;
    let urlConfirmity = process.env.REACT_APP_URL_REDIRECT_TO_CONFIRMITY_DOC + taskId;

    return(
      <div className='profile'>
        <Link className='back-to-services'
              to={{ pathname: '/visita/'+this.state.taskId+'', token: this.state.token, helperId: this.state.helperId }} >
          <span><FontAwesomeIcon icon={ faArrowLeft } /></span>
        </Link>

        <TaskTitle taskId={taskId} />

        <UploadPhotosButton
          taskId={taskId} userData={userData} token={token} uploadToken={uploadToken}
          uploadPath={"/subir-fotos-antes-visita"} buttonText={"Fotos antes"}
          isPhotos={this.state.isPhotosBefore}
        />
      </div>
    )
  }
}

export default VisitProfile;
