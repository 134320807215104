import React from 'react';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import * as IoIcons from 'react-icons/io';

export const SidebarData = [
  {
    title: 'Servicios disponibles',
    path: '/servicios_disponibles',
    cName: 'nav-text'
  },
  {
    title: 'Mis servicios',
    path: '/servicios',
    cName: 'nav-text'
  },
  {
    title: 'Calendario',
    path: '/calendario',
    cName: 'nav-text'
  }
  
];