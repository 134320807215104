import Swal from "sweetalert2";

import './popup-message.styles.scss';

const PopupConfirm = (icon, title, taskId, route) => {
  Swal.fire({
    icon: icon,
    title: title,
    allowOutsideClick: false,
    showConfirmButton: true,
    showCancelButton: true,
    showCloseButton: false,
    confirmButtonText: 'Continuar',
    cancelButtonText: 'Volver',
    customClass: 'mh-modal'
  }).then((result) => {
    if(result.value) {
      if(route){
        window.location = '/' + route;
      } else if (icon === 'success' && taskId !== 'none') {
        window.location = '/' + taskId;
      }
    }
  });
};

export default PopupConfirm;
