import React from 'react';
import Header from '../../components/header/header.component';
import Task from '../../components/task/task.component';
import Footer from '../../components/footer/footer.component';

const TaskPage = (props) => (
  <div className='parent'>
    <Header />

    <Task {...props} />

    <Footer />
  </div>
);

export default TaskPage;
