import React from 'react';
import { Link } from 'react-router-dom';
import CustomButton from '../custom-button/custom-button.component';
import * as PropTypes from 'prop-types';


const UploadPhotosButton = (props) => {
  return <div>
    <Link to={{
      pathname: "/" + props.taskId + props.uploadPath,
      query: {userData: props.userData, taskId: props.taskId, token: props.token, uploadToken: props.uploadToken}
    }}>
      <CustomButton className={"custom-button " + (props.isPhotos ? "green-button" : "")} onClick={props.onClick} >
        {props.buttonText}
      </CustomButton>
    </Link>
  </div>;
};

UploadPhotosButton.propTypes = {
  taskId: PropTypes.any,
  userData: PropTypes.any,
  token: PropTypes.any,
  uploadToken: PropTypes.any,
  uploadPath: PropTypes.any,
  buttonText: PropTypes.any,
  isPhotos: PropTypes.any
};

export default UploadPhotosButton;
