import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import 'typeface-raleway';
import App from './App';



const clarityScript = document.createElement('script');
  clarityScript.innerHTML = '(function(c,l,a,r,i,t,y){ c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)}; t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i; y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y); })(window, document, "clarity", "script", "3xm7dsboko");';
  clarityScript.async = true;
  document.head.prepend(clarityScript);


const mscript = document.createElement('script');
  mscript.innerHTML = "window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', 'GTM-MDQG789');";
  mscript.async = true;
  document.head.prepend(mscript);

const script = document.createElement('script');
  script.src = "https://www.googletagmanager.com/gtag/js?id=UA-62778743-1";
  script.async = true;
  document.head.prepend(script);

const gscript = document.createElement('script');
  gscript.src = "https://www.googletagmanager.com/gtm/js?id=GTM-MDQG789";
  gscript.async = true;
  document.head.prepend(gscript);

ReactDOM.render(
  <App />,
  document.getElementById('root')
);
