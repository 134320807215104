import React from 'react';
import './taskcalendar.styles.scss';
import { Calendar, Views, momentLocalizer } from 'react-big-calendar'
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import LoadingSkeleton from '../loading-skeleton/loading-skeleton.component';
import moment from 'moment';
import { faRubleSign } from '@fortawesome/free-solid-svg-icons';
import DayEvent from './dayevent.component';
import MonthEvents from './monthevents.components';
import WeekEvents from './weekevents.components';


class taskcalendar extends React.Component {


    signal = axios.CancelToken.source();

    constructor(props) {
      super(props);
  
      this.state = {
        serviceData: [],
        visitsData:[],
        userData: this.props.location.search ?
                  this.props.location.search :
                  JSON.parse(localStorage.getItem('task')).userData,
        redirect:    false,
        showFilters: false,
        filtersTitle: 'Ver Filtros',
        idInputValue: '',
        filteredArray: [],
        selectedStageOption: '',
        stageOptions: [],
        selectedDateOption: '',
        dateOptions: [],
        currentPage: 1,
        token: false,
        helperId: false
      };
      
      localStorage.setItem('task', JSON.stringify(this.state));
      
      //this.handleFilters = this.handleFilters.bind(this);
    }
  
    controller = new AbortController();
  
    handlePageChange = (page, e) => {
      this.setState({
        currentPage: page
      });
    };
  
    decryptUserData() {
      const decryptedStr = this.handleHash(this.state.userData);
      const passwordArr = decryptedStr.split("password=");
      const pass = passwordArr[1];
      const usernameArr = passwordArr[0].split("username=");
      const user = usernameArr[1].slice(0,-1);
  
      return [user, pass];
    }
  
    handleHash(hash) {
      try {
        
        return atob(hash.substr(1));
      } catch (e) {
        this.setState({redirect: true});
      }
    }
  
    loginCheckAPI(urlLoginCheckAPI, userObject) {
      axios.post(urlLoginCheckAPI, userObject).then(
        result => {
          this.setState({token: result.data.token});
  
          const preparedConfig = {
            headers: {
              'Authorization': `Bearer ${this.state.token}`,
              'accept': 'application/json'
            },
  
          };
  
          const urlGetHelperId = process.env.REACT_APP_API_URL + 'api/fos_users?email=' + userObject.username;
          this.getHelperId(urlGetHelperId, preparedConfig);
  
        },
        error => {
          window.location.href = "https://multihelpers.com/login";
        }
      )
    }
  
    getHelperId(urlGetHelperId, preparedConfig) {
      axios.get(urlGetHelperId, preparedConfig).then(
        result => {
          this.setState({
            helperId: result.data[0].id
          });

          const urlIsHelperActiveCheckAPI = process.env.REACT_APP_API_URL + 'api/helpers/' + result.data[0].id + '/is_helper_active';
          this.getIsHelperActive(urlIsHelperActiveCheckAPI, preparedConfig);
  
          localStorage.setItem('taskList', JSON.stringify(this.state));
          const urlGetHelperTasks = process.env.REACT_APP_API_URL + 'api/helpers/' + result.data[0].id + '/services';
          const urlGetHelperVisits = process.env.REACT_APP_API_URL + 'api/helpers/' + result.data[0].id + '/visits';  
          this.getHelperTasks(urlGetHelperTasks, preparedConfig);
          this.getHelpervisits(urlGetHelperVisits, preparedConfig);
  
        }, error => {
          console.log(error);
          this.setState({redirect: true});
        }
      );
    }

    getIsHelperActive(urlIsHelperActiveCheckAPI, preparedConfig) {
      axios.get(urlIsHelperActiveCheckAPI, preparedConfig).then(
        result => {
          if(!result.data)
            this.setState({redirect: true});
        },
        error => {
          this.setState({redirect: true});
        }
      );
    }
  
    componentDidMount() {
      let secureArray = this.decryptUserData();
      
      const userObject = {
        username: secureArray[0],
        password: secureArray[1]
      };
      const urlLoginCheckAPI = process.env.REACT_APP_API_URL + 'login_check';
  
      this.loginCheckAPI(urlLoginCheckAPI, userObject, secureArray);
    }
  
    componentWillUnmount() {
      this.signal.cancel('API is being canceled');
      this.controller.abort();
    }
  
    getHelperTasks(urlGetHelperTasks, preparedConfig) {
      axios.get(urlGetHelperTasks, preparedConfig)
      .then(
        result => {
          this.setState({
            serviceData: result.data,
            //filteredArray: this.paginatorNormalizer(result.data),
            //stageOptions: this.stageOptionsNormalizer(result.data),
            //dateOptions: this.dateOptionsNormalizer(result.data)
          });
        }
      )
    }

    getHelpervisits(urlGetHelperVisits, preparedConfig) {
      axios.get(urlGetHelperVisits, preparedConfig)
      .then(
        result => {
          this.setState({
            visitsData: result.data,
            //filteredArray: this.paginatorNormalizer(result.data),
            //stageOptions: this.stageOptionsNormalizer(result.data),
            //dateOptions: this.dateOptionsNormalizer(result.data)
          });
        }
      )
    }
  
    removeArrDuplicates = (originalArray, prop) => {
      let newArray = [];
      let lookupObject  = {};
  
      for(let i in originalArray) {
        lookupObject[originalArray[i][prop]] = originalArray[i];
      }
      for(let i in lookupObject) {
        newArray.push(lookupObject[i]);
      }
  
      return newArray;
    };
  
    render() {
      if (this.state.redirect) {
        return ( <Redirect to={{ pathname: '/' }} /> );
      }
  
      if (typeof this.state.serviceData === 'undefined' || this.state.serviceData.length === 0) {
        return ( <LoadingSkeleton /> );
      }


      const MonthEvent = ({ event }) => (
        <div>
          <div>{event.title}</div>
        </div>
      );
        

      require('moment/locale/es.js');
      const mylocalizer = momentLocalizer(moment);

        const myEvents = [];
        
        for(let taskObj of this.state.serviceData) {
          if(taskObj.isResolutionDataSentToHelper || taskObj.isResolutionDataSentToAdvertiser){
            if(!taskObj.addressObsevations){taskObj.addressObsevations = ''}
            myEvents.push({
            id: taskObj.id,
            isVisit: false,
            title: '' + taskObj.id,
            allDay: false,
            start: new Date(taskObj.executionDatetimeUS),
            end: new Date(taskObj.executionDatetimeUS),
            daylabel: 'ID ' + taskObj.id + ' ' + taskObj.executionDatetime,
            popaplabeldate: taskObj.executionDatetime,
            location: taskObj.addressStreet +' '+ taskObj.addressNumber + ' ' +taskObj.addressObsevations+ '(' + taskObj.postalcode + ')',
            perfilHelperStage: taskObj.perfilHelperStage
            });
        }}
        for(let visitObj of this.state.visitsData) {
          if(visitObj.isVisitDataSentToHelper){
            if(!visitObj.addressObsevations){visitObj.addressObsevations = ''}
            myEvents.push({
            id: visitObj.id,
            isVisit: true,
            title: '' + visitObj.id,
            allDay: false,
            start: new Date(visitObj.visitDatetimeUS + ' ' + visitObj.visitTime),
            end: new Date(visitObj.visitDatetimeUS + ' ' + visitObj.visitTime),
            daylabel: 'VISITA ID ' + visitObj.id + ' ' + visitObj.visitDate+' ' + visitObj.visitTime,
            popaplabeldate: visitObj.visitDatetimeUS + ' ' + visitObj.visitTime,
            location: visitObj.addressStreet +' '+ visitObj.addressNumber + ' ' +visitObj.addressObsevations+ '(' + visitObj.postalcode + ')',
            perfilHelperStage: visitObj.perfilHelperStage
            });
        }}
   
      return (
        <div>
          <Calendar
            localizer={mylocalizer}
            events={myEvents}
            startAccessor="start"
            timeslots={1}
            //toolbar={false}
            endAccessor="end"
            views={{month: true, week: true, day: true}}
            drilldownView="day"
            onSelectEvent={this.handleEventClick}
            popup={true}
            messages={{
              next: "sig",
              previous: "ant",
              today: "Hoy",
              month: "Mes",
              week: "Semana",
              day: "Día"
            }}
            components={{
              day:{event: DayEvent,},
              month:{event: MonthEvents,},
              week:{event: WeekEvents,},
            }}
            eventPropGetter={
              (event, start, end, isSelected) => {
                let newStyle = {
                  backgroundColor: "rgb(0, 205, 237)",
                  color: 'white',
                };
          
                if (event.perfilHelperStage == "Pendiente de pago"){
                  newStyle.backgroundColor = "#ffbd59";
                  newStyle.color = "white";
                }else if(event.perfilHelperStage == "Finalizado"){
                  newStyle.backgroundColor = "#8cb70d";
                  newStyle.color = "white";
                }else{
                  newStyle.backgroundColor = "#00cded";
                  newStyle.color = "white";
                }
          
                return {
                  className: "",
                  style: newStyle
                };
              }
            }
          />
        </div>        
      )
    }
}

  
export default taskcalendar;