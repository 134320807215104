import React from 'react';

import Header from '../../components/header/header.component';
import Profile from '../profile/profile.component';
import Footer from '../../components/footer/footer.component';

const Documents = (props) => (
  <div className='parent'>
    <Header />

    <Profile {...props} />

    <Footer />
  </div>
);

export default Documents;
