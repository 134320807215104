import React from 'react';
import './task-title.styles.scss';

const TaskTitle = ({taskId}) => (
    <div className='task-title'>
      <h3>ID {taskId}</h3>
    </div>
);

export default TaskTitle;
