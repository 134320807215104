import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import TaskPage from './pages/task-page/task-page.component';
import VisitPage from './pages/visit-page/visit-page.component';
import TaskList from './pages/task-list/task-list.component';
import TaskCalendar from './pages/task-calendar/task-calendar.component';
import TasksAvailable from './pages/tasks-available/tasks-available.component';
import AvailableTask from './pages/task-available/task-available.component';
import UploadBeforePhotos from './pages/upload-before-photos/upload-before-photos.component';
import UploadBeforeVisitPhotos from './pages/upload-before-visit-photos/upload-before-visit-photos.component';
import UploadAfterPhotos from './pages/upload-after-photos/upload-after-photos.component';
import UploadInvoice from './pages/upload-invoice/upload-invoice.component';
import NotFoundPage from './pages/not-found-page/not-found-page.component';
import './App.css';
import TaskImages from './pages/task-images/task-images.component';
import AcceptCalendar from './pages/accepted-task-calendar/accepted-task-calendar.component';
import Documents from './pages/documents/documents.component';
import VisitDocuments from './pages/visit-documents/visit-documents.component';
import TaskAvailableImages from './pages/task-available-images/task-images.component';
import TaskScheduleVisit from './pages/task-visit-schedule/task-schedule-visit.component';
import VisitCalendar from './pages/task-visit-schedule-calendar/task-visit-schedule-calendar.component';


const App = () => (
  <Router>
    <Switch>
      <Route exact path='/servicios' component={TaskList} />
      <Route exact path='/calendario' component={TaskCalendar} />
      <Route exact path='/servicios_disponibles' component={TasksAvailable} />
      <Route exact path='/:taskId/subir-fotos-antes' component={UploadBeforePhotos} />
      <Route exact path='/:taskId/subir-fotos-antes-visita' component={UploadBeforeVisitPhotos} />
      <Route exact path='/:taskId/subir-fotos-despues' component={UploadAfterPhotos} />
      <Route exact path='/:taskId/subir-factura' component={UploadInvoice} />
      <Route exact path='/:taskId/calendario' component={AcceptCalendar} />
      <Route exact path='/:taskId' component={TaskPage} />
      <Route exact path='/visita/:taskId' component={VisitPage} />
      <Route exact path='/:taskId/disponible' component={AvailableTask} />
      <Route exact path='/:taskId/ver-fotos' component={TaskImages} />
      <Route exact path='/:taskId/disponible/ver-fotos' component={TaskAvailableImages} />
      <Route exact path='/:taskId/documentos' component={Documents}/>
      <Route exact path='/:taskId/documentos-visita' component={VisitDocuments}/>
      <Route exact path='/:taskId/agendar_visita' component={TaskScheduleVisit}/>
      <Route exact path='/:taskId/agendar_visita_calendario' component={VisitCalendar}/>

      <Route path="/" component={NotFoundPage} />
    </Switch>
  </Router>
);

export default App;

