import React from 'react';
import './task-images.style.scss';
import Header from '../../components/header/header.component';
import Task from '../../components/task/task.component';
import ImageSlider from '../../components/task-available-images/task-image-slider.component';
import Footer from '../../components/footer/footer.component';

const Taskimages = (props) => (
  <div className='parent'>
    <Header />
    <div className="slide-container">
      <ImageSlider {...props} />
    </div>
    <Footer />
  </div>
);

export default Taskimages;
